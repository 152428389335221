export const districts={
  AndhraPradesh: [
    'Anantapur',
    'Chittoor',
    'East Godavari',
    'Guntur',
    'Krishna',
    'Kurnool',
    'Nellore',
    'Prakasam',
    'Srikakulam',
    'Visakhapatnam',
    'Vizianagaram',
    'West Godavari',
  ],
  ArunachalPradesh: [
    'Changlang',
    'East Kameng',
    'East Siang',
    'Itanagar',
    'Kra Daadi',
    'Kurung Kumey',
    'Lepa Rada',
    'Lower Dibang Valley',
    'Lower Subansiri',
    'Namsai',
    'Pakke Kessang',
    'Papum Pare',
    'Tawang',
    'Tirap',
    'Upper Dibang Valley',
    'Upper Siang',
    'Upper Subansiri',
    'West Kameng',
    'West Siang',
  ],
  Assam: [
    'Baksa',
    'Barpeta',
    'Bongaigaon',
    'Cachar',
    'Darrang',
    'Dhemaji',
    'Dibrugarh',
    'Goalpara',
    'Golaghat',
    'Hailakandi',
    'Jorhat',
    'Karbi Anglong',
    'Karimganj',
    'Kokrajhar',
    'Lakhimpur',
    'Morigaon',
    'Nagaon',
    'Nalbari',
    'Sivasagar',
    'Sonitpur',
    'Tinsukia',
  ],
  Bihar: [
    'Araria',
    'Aurangabad',
    'Banka',
    'Buxar',
    'Darbhanga',
    'Gaya',
    'Jammu',
    'Katihar',
    'Kishanganj',
    'Madhubani',
    'Munger',
    'Nalanda',
    'Nawada',
    'Patna',
    'Rohtas',
    'Saran',
    'Sheikhpura',
    'Siwan',
    'Vaishali',
  ],
  Gujarat: [
    'Ahmedabad',
    'Amreli',
    'Anand',
    'Aravalli',
    'Banaskantha',
    'Bharuch',
    'Bhavnagar',
    'Dahod',
    'Dang',
    'Gandhinagar',
    'Jamnagar',
    'Junagadh',
    'Kutch',
    'Mehsana',
    'Narmada',
    'Navsari',
    'Panchmahal',
    'Patan',
    'Porbandar',
    'Sabarkantha',
    'Surat',
    'Tapi',
    'Vadodara',
    'Valsad',
  ],
  Haryana: [
    'Ambala',
    'Bhiwani',
    'Charkhi Dadri',
    'Faridabad',
    'Fatehabad',
    'Gurugram',
    'Hisar',
    'Jhajjar',
    'Jind',
    'Karnal',
    'Kurukshetra',
    'Mahendragarh',
    'Panchkula',
    'Panipat',
    'Rewari',
    'Sirsa',
    'Sonipat',
    'Yamunanagar',
  ],
  Karnataka: [
    'Bagalkot',
    'Ballari',
    'Bengaluru Rural',
    'Bengaluru Urban',
    'Belagavi',
    'Bellary',
    'Bidar',
    'Chamarajanagar',
    'Chikkaballapur',
    'Chikmagalur',
    'Chitradurga',
    'Dakshina Kannada',
    'Davanagere',
    'Dharwad',
    'Gadag',
    'Hassan',
    'Haveri',
    'Kalaburagi',
    'Kodagu',
    'Kolar',
    'Koppal',
    'Mandya',
    'Mysuru',
    'Raichur',
    'Ramanagara',
    'Shimoga',
    'Tumkur',
    'Udupi',
    'Uttara Kannada',
  ],
  Kerala: [
    'Alappuzha',
    'Ernakulam',
    'Idukki',
    'Kannur',
    'Kasaragod',
    'Kollam',
    'Kottayam',
    'Kozhikode',
    'Malappuram',
    'Palakkad',
    'Pathanamthitta',
    'Thiruvananthapuram',
    'Thrissur',
    'Wayanad',
  ],
  MadhyaPradesh: [
    'Ashoknagar',
    'Balaghat',
    'Barwani',
    'Betul',
    'Bhind',
    'Bhopal',
    'Burhanpur',
    'Chhindwara',
    'Dewas',
    'Dhar',
    'Dindori',
    'Guna',
    'Gwalior',
    'Harda',
    'Hoshangabad',
    'Indore',
    'Jabalpur',
    'Jhabua',
    'Katni',
    'Khandwa',
    'Khargone',
    'Mandla',
    'Mandsaur',
    'Morena',
    'Narmada',
    'Neemuch',
    'Panna',
    'Rewa',
    'Sagar',
    'Satna',
    'Sehore',
    'Seoni',
    'Shahdol',
    'Shajapur',
    'Sheopur',
    'Shivpuri',
    'Sidhi',
    'Singrauli',
    'Ujjain',
    'Umaria',
    'Vidisha',
  ],
  Maharashtra: [
    'Ahmednagar',
    'Akola',
    'Amravati',
    'Aurangabad',
    'Beed',
    'Bhandara',
    'Buldhana',
    'Chandrapur',
    'Dhule',
    'Gadchiroli',
    'Gondia',
    'Hingoli',
    'Jalgaon',
    'Jalna',
    'Kolhapur',
    'Latur',
    'Mumbai City',
    'Mumbai Suburban',
    'Nagpur',
    'Nanded',
    'Nandurbar',
    'Nashik',
    'Osmanabad',
    'Parbhani',
    'Pune',
    'Raigad',
    'Ratnagiri',
    'Sindhudurg',
    'Solapur',
    'Thane',
    'Wardha',
    'Washim',
    'Yavatmal',
  ],
  Rajasthan: [
    'Ajmer',
    'Alwar',
    'Banswara',
    'Baran',
    'Barmer',
    'Bhilwara',
    'Bikaner',
    'Bundi',
    'Chittorgarh',
    'Dausa',
    'Dholpur',
    'Dungarpur',
    'Hanumangarh',
    'Jaipur',
    'Jaisalmer',
    'Jalore',
    'Jhalawar',
    'Jhunjhunu',
    'Jodhpur',
    'Karauli',
    'Kota',
    'Nagaur',
    'Pali',
    'Rajasamand',
    'Sawai Madhopur',
    'Sikar',
    'Sirohi',
    'Tonk',
    'Udaipur',
  ],
  TamilNadu: [
    'Chennai',
    'Coimbatore',
    'Cuddalore',
    'Dharmapuri',
    'Dindigul',
    'Erode',
    'Kanchipuram',
    'Kanyakumari',
    'Karur',
    'Krishnagiri',
    'Madurai',
    'Nagapattinam',
    'Namakkal',
    'Perambalur',
    'Pudukkottai',
    'Ramanathapuram',
    'Salem',
    'Sivagangai',
    'Tenkasi',
    'Tiruchirappalli',
    'Tirunelveli',
    'Tiruppur',
    'Vellore',
    'Villupuram',
    'Virudhunagar',
  ],
  Telangana: [
    'Adilabad',
    'Bhadradri Kothagudem',
    'Hyderabad',
    'Jagtial',
    'Jangaon',
    'Jayashankar Bhupalpally',
    'Jogulamba Gadwal',
    'Kamareddy',
    'Karimnagar',
    'Khammam',
    'Mahabubabad',
    'Mahabubnagar',
    'Mancherial',
    'Medak',
    'Medchal-Malkajgiri',
    'Nagarkurnool',
    'Nalgonda',
    'Nirmal',
    'Nizamabad',
    'Peddapalli',
    'Rajanna Sircilla',
    'Ranga Reddy',
    'Sangareddy',
    'Warangal (Rural)',
    'Warangal (Urban)',
    'Yadadri Bhuvanagiri',
  ],
  UttarPradesh: [
    'Agra',
    'Aligarh',
    'Allahabad',
    'Ambedkar Nagar',
    'Amethi',
    'Amroha',
    'Auraiya',
    'Azamgarh',
    'Badaun',
    'Bahraich',
    'Ballia',
    'Balrampur',
    'Banda',
    'Barabanki',
    'Bareilly',
    'Basti',
    'Bijnor',
    'Bulandshahr',
    'Chandauli',
    'Chitrakoot',
    'Deoria',
    'Etah',
    'Etawah',
    'Faizabad',
    'Farrukhabad',
    'Fatehpur',
    'Firozabad',
    'Gautam Buddh Nagar',
    'Ghaziabad',
    'Ghazipur',
    'Gonda',
    'Gorakhpur',
    'Hamirpur',
    'Hapur',
    'Hardoi',
    'Hathras',
    'Jalaun',
    'Jaunpur',
    'Jhansi',
    'Jhansi',
    'Kannauj',
    'Kanpur Dehat',
    'Kanpur Nagar',
    'Kanshiram Nagar',
    'Kushinagar',
    'Lakhimpur Kheri',
    'Lalitpur',
    'Lucknow',
    'Mau',
    'Meerut',
    'Mirzapur',
    'Moradabad',
    'Muzaffarnagar',
    'Pilibhit',
    'Pratapgarh',
    'Raebareli',
    'Rampur',
    'Saharanpur',
    'Sambhal',
    'Sant Kabir Nagar',
    'Sant Ravidas Nagar',
    'Shahjahanpur',
    'Shamli',
    'Shravasti',
    'Siddharthnagar',
    'Sitapur',
    'Sonbhadra',
    'Sultanpur',
    'Unnao',
    'Varanasi',
  ],
  WestBengal: [
    'Alipurduar',
    'Bankura',
    'Bardhaman',
    'Birbhum',
    'Cooch Behar',
    'Dakshin Dinajpur',
    'Darjeeling',
    'Hooghly',
    'Howrah',
    'Jalpaiguri',
    'Jhargram',
    'Kolkata',
    'Maldah',
    'Medinipur',
    'Murshidabad',
    'Nadia',
    'North 24 Parganas',
    'Paschim Bardhaman',
    'Paschim Medinipur',
    'Purba Bardhaman',
    'Purba Medinipur',
    'Purulia',
    'South 24 Parganas',
    'Uttar Dinajpur',
  ],
};
    export const BG =[
      "Choose...",
      "A+",
      "A-",
      "B+",
      "B-",
      "O+",
      "O-",
      "AB+",
      "AB-"
    ];
  
    export const Gender =[
      "Choose...",
      "Male",
      "Female",
      "Other"
    ];
  
  
    export const Departments =[
      "Choose...",
      "Marketing",
      "Web Development",
      "Testing",
      "Academy"
    ];
  
    export const EmployeeType =[
      "Choose...",
      "Permanent",
      "Temporary",
      "Intern"
    ];
  
    export const DutyType =[
      "Choose...",
      "Full Time",
      "Part Time"
    ];
  
    export const ShiftType=[
      "Choose...",
      "Morning",
      "Afternoon",
      "Evening",
      "Night",
      "General"
    ]
  
    export const ManagementErrors = {
      requiredField: '"This field is required."',
      invalidFormat: '"The input format is invalid."',
      minLength: '"The input is too short."',
      maxLength: '"The input is too long."',
      email: '"The email address is invalid."',
      passwordMismatch: '"Passwords do not match."',
      invalidCredentials: '"The username or password is incorrect."',
      accountLocked: '"The account is locked due to multiple failed login attempts."',
      tokenExpired: '"The session token has expired."',
      unauthorizedAccess: '"You do not have permission to access this resource."',
      connectionFailed: '"Failed to connect to the database."',
      queryFailed: '"Database query failed."',
      recordNotFound: '"The requested record was not found."',
      duplicateRecord: '"A record with the same identifier already exists."',
      timeout: '"The request timed out."',
      offline: '"The system is currently offline."',
      serverUnavailable: '"The server is currently unavailable."',
      requestFailed: '"The request failed due to a network error."',
      unexpectedError: '"An unexpected error occurred."',
      featureUnavailable: '"This feature is currently unavailable."',
      dataProcessingError: '"An error occurred while processing the data."',
      configurationError: '"There is a configuration error in the system."',
      userNotFound: '"User not found."',
      accessDenied: '"User does not have the necessary permissions."',
      profileIncomplete: '"User profile is incomplete."',
      actionNotAllowed: '"User action is not allowed."'
    };